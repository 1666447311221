@import '@angular/material/theming';
@import '../app/modules/integration/integration.component.scss';
@import '../app/shared/components/sidemenu/sidemenu.component.scss';
@import '../app/shared/components/sidemenu-item/sidemenu-item.component.scss';
@import '../app/shared/dashboard-widget/dashcard/dashcard.component.scss';
@import '../app/shared/dashboard-widget/profile-card/profile-card.component.scss';
@import '../app/shared/dashboard-widget/round-progressbar/round-progressbar.component.scss';
@import '../app/shared/dashboard-widget/sales-list/sales-list.component.scss';

$mat-light-theme-background: (
//  status-bar: map_get($mat-grey, 300),
  status-bar: map_get($mat-grey, 300),
  app-bar:    map_get($mat-grey, 100),
  background: map_get($mat-indigo, 50),
  hover:      rgba(black, 0.04), // TODO(kara): check style with Material Design UX
  card:       white,
  dialog:     white,
  disabled-button: $black-12-opacity,
  raised-button: white,
  focused-button: $black-6-opacity,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
  disabled-list-option: map_get($mat-grey, 200),
);

@include mat-core();

$primary : $mat-blue;
$accent  : $mat-orange;
$warn    : $mat-red;



$primary-app-primary: mat-palette($primary , 700);
$primary-app-accent:  mat-palette($accent , 900);
$primary-app-warn:    mat-palette($warn );
$cdk-theme:   mat-light-theme($primary-app-primary, $primary-app-accent,$primary-app-warn);

.angular-editor .angular-editor-wrapper p {
  margin-bottom: 4% !important;
}

.fr-box.fr-basic.fr-top .fr-wrapper {
  height: 30em;
  overflow-y: scroll;
}

.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: none !important;
}

cdk-sidemenu{
    $sidemenu-app-primary: mat-palette($primary , 400);
    $sidemenu-app-accent:  mat-palette($accent , 900);
    $sidemenu-app-warn:    mat-palette($warn );
    $cdk-sidemenu-theme:   mat-dark-theme($sidemenu-app-primary, $sidemenu-app-accent,$sidemenu-app-warn);
    @include mat-list-theme($cdk-sidemenu-theme);
}


cdk-sidemenu-item{
    @include sidemenu-item($cdk-theme,20px)
}


cdk-dashcard {
    $dashcard-primary: mat-palette($primary);
    $dashcard-accent:  mat-palette($accent);
    $dashcard-warn:    mat-palette($warn);
    $dashcard-theme:   mat-dark-theme($dashcard-primary, $dashcard-accent,$dashcard-warn);
    @include dashcard($dashcard-theme);
}

cdk-profile-card{
  $dashboard-profile-primary: mat-palette($primary , 400);
    $dashboard-profile-accent:  mat-palette($accent , 900);
    $dashboard-profile-warn:    mat-palette($warn );
    $cdk-dashboard-profile-theme:   mat-dark-theme($dashboard-profile-primary, $dashboard-profile-accent,$dashboard-profile-warn);
    @include dashboard-profile($cdk-dashboard-profile-theme);
    @include mat-tabs-theme($cdk-dashboard-profile-theme);
}
@include angular-material-theme($cdk-theme);
@include auth($cdk-theme);
@include round-progressbar($cdk-theme);
@include sales-list($cdk-theme);

html {
  height:100%;
}
body{
  padding: 0px !important;
  margin: 0px !important;
  height:100%;
  background: #000000 !important;
  margin-top: -0.5% !important;
}
.components-container-gt-xs {
    padding: 20px;
    // background-color: #fdfdfd;
}
.components-container-xs {
    padding: 5px !important;
    // background-color: #fdfdfd;
}
.component-preview {
    padding: 20px;
}
.ps-content {
  height: 100%;
}

.loader-container {
    background-color: white;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    position: fixed;

    -moz-animation: cssAnimation 1s ease-in 3s forwards;
    /* Firefox */
    animation: cssAnimation 1s ease-in 3s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 1s ease-in 3s forwards;
    /* Opera */
    animation: cssAnimation 1s ease-in 3s forwards;
    animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes cssAnimation {
    to {
        opacity:0;
        display: none;
        visibility:hidden;
    }
}
@keyframes cssAnimation {
    to {
        visibility:hidden;
        opacity:0;
        display: none;
    }
}



.spinner {
    margin: 50vh auto 0;
    width: 70px;
    text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;


  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.bounce3 {
    background-color: orange;
}

.spinner .bounce1 {
    background-color: blue;
  animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
    background-color: red;
  animation-delay: -0.16s;
  animation-delay: -0.16s;
}



@keyframes sk-bouncedelay {
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
    transform: scale(1.0);
  }
}

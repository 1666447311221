@mixin mat-list-icon($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}
@mixin mat-chip() {
  padding: 1px 7px;
}

@mixin sidemenu-item($theme, $icon-size) {
  .sidenav-dropdown-indicator {
    transition: transform 0.25s;
    &.indicateOpen {
      transform: rotate(180deg);
    }
  }
  mat-nav-list {
    overflow: hidden;
    padding-top: 0px;
  }
  .primary {
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    padding-top: 0px;
  }
  .secondaryMenu {
    padding-left: 10px;
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    padding-top: 0px;
  }

  .mat-nav-list .mat-list-item .mat-list-icon {
    @include mat-list-icon($icon-size);
  }
  .mat-chip:not(.mat-basic-chip) {
    @include mat-chip();
  }
  .mat-nav-list .mat-list-item {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }
}
h3 {
  height: 18px;
}

.sidebarSync {
  background: #2a3040;
  position: absolute;
  bottom: 30px;
  color: white;
  font-family: 'Open Sans', Arial, sans-serif;
  width: 100%;
  padding: 15px 0px;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.syncSideMenu {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Open Sans', Arial, sans-serif;
}

.lastSyncSideMenu {
  font-size: 14px;
  font-family: 'Open Sans', Arial, sans-serif;
}

.ripple {
  // margin: auto;
  // margin-top: 5rem;
  // background-color: #fff;
  // width: 30px;
  // height: 30px;
  border-radius: 50%;
  display: grid;
  animation: ripple 3s ease-out infinite;
}

.ripple::before,
.ripple::after {
  content: "";
  // grid-area: 1/1;
  border-radius: 50%;
  animation: inherit;
  animation-delay: 5s;
  
}

.ripple::after {
  animation-delay: 5s;
  width: 15px !important;
  height: 15px !important;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0.75rem rgba(255, 255, 255, 0.2);
    
  }
  100% {
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0);
  }
}
.imageHover:hover {
  background-color: #40465B66 !important;
  border-radius: 50%;
  // transition: 0.25s ease-in background-color, 1rem ease-in opacity, 5s ease-out transform;
  cursor: pointer;
  
}
:host::ng-deep .ps {
  position: static;
  display: block;
  width: inherit;
  /* height: inherit; */
  max-width: inherit;
  max-height: inherit;
  height: 77% !important;
}

.RippleBefore{
  margin-top: 6px;
  font: normal normal normal 12px/17px Open Sans, sans-serif;
}
.RippleAfter{
  margin-top: -13px;
  font: normal normal normal 12px/17px Open Sans, sans-serif;
}